'use strict';
$(document).ready(function () {
if($(window).width() > 768){
    var tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-7_container",
            pin: ".section-7_container",
            end: "+=60",
            start: "top top",
            scrub: .3,
        },
    });

    // var tl2 = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".section-6-title",
    //         endTrigger: ".section-6_container",
    //         end: "bottom bottom",
    //         start: "top top",
    //         scrub: .3,
    //     },
    // });
    // var tl3 = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".section-6_part",
    //         endTrigger: ".section-6_part",
    //         end: "bottom bottom",
    //         start: "top top",
    //         scrub: .3,
    //     },
    // });


    var textItems = gsap.utils.toArray(".text_item");

    // tl2.add('start').to(textItems, { y: -1000}, 'start');

    // tl3.add('title').to('.section-6-title, .img', { y: 1000}, 'title');

    tl.add('start')
        .from(".first", {  x: -200,  y: 200, autoAlpha: 0, }, 'start')
        .from(".second", { x: 200,  y: 200, autoAlpha: 0, }, 'start')
    }
});
